<div id="divScanToPay">
    <mat-card class="scan-to-pay-container merchant-card">
        <img *ngIf="merchant && selectedIndex === 1" mat-card-image src="{{merchant.logo}}" alt="Merchant" class="merchant-logo">
        <h1 *ngIf="merchant && selectedIndex === 1" style="text-align: center;color: #000">{{merchant.businessName}}
        </h1>
        <mat-card-content>
            <div fxLayout="column">
                <div *ngIf="merchant && selectedIndex === 1" fxLayout="row" fxLayoutAlign="center center">
                    <h2 style="text-align: center;color: #000;font-weight: 600;">Would you like to add a tip?</h2>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column">
                    <mat-vertical-stepper [linear]="false" (selectionChange)="onStepChange($event)" #stepper>
                        <mat-step>
                            <div fxLayout="column" [class.spinner]="loading">

                            </div>
                        </mat-step>
                        <mat-step [stepControl]="gratuityFormGroup" class="gratuity">
                            <form [formGroup]="gratuityFormGroup" style="width: 100%">
                                <div fxLayout="column" *ngIf="order" style="width: 95%">
                                    <div fxLayout="row" class="tip-buttons">
                                        <button mat-stroked-button mat-raised-button (click)="SetTip(0.10,'p'); $event.target.focus()" class="tip-button" data-test="Scan-To-Pay10%Button">10%</button>
                                        <button mat-stroked-button mat-raised-button (click)="SetTip(0.15,'p'); $event.target.focus()" class="tip-button" data-test="Scan-To-Pay15%Button">15%</button>
                                        <button mat-stroked-button mat-raised-button (click)="SetTip(0.18,'p'); $event.target.focus()" class="tip-button" data-test="Scan-To-Pay18%Button">18%</button>
                                        <button mat-stroked-button mat-raised-button (click)="SetTip(0.20,'p'); $event.target.focus()" class="tip-button" data-test="Scan-To-Pay20%Button">20%</button>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 5px">
                                        <button mat-stroked-button mat-raised-button (click)="SetTip(0.0,'c'); $event.target.focus()" class="custom-tip-button" data-test="Scan-To-PayCustomButton">Custom</button>
                                        <button mat-stroked-button mat-raised-button (click)="SetTip(0.0,'p'); $event.target.focus()" class="custom-tip-button" data-test="Scan-To-PayNoTipButton">No Tip</button>
                                    </div>
                                    <div *ngIf="isCustomTip" fxLayout="row" fxLayoutAlign="end center" style="margin-top: 5px">
                                        <input formControlName="customTipAmount" class="form-control right-radius custom-tip-box" type="number" (change)="updateTotal()" [textMask]="{mask: regexList.currency_mask}" placeholder="Amount" [(ngModel)]="tipAmount" data-test="Scan-To-PayCustomTipAmountInput"/>
                                    </div>
                                    <div fxLayout="row" class="row-margin total">
                                        <div fxLayout="column" fxFlex="50%">
                                            <h3 style="margin-left: 5px; font-size: 600">Subtotal</h3>
                                        </div>
                                        <div fxLayout="column" fxFlex="50%" fxLayoutAlign="center end">
                                            <h3 style="margin-right: 5px; font-size: 600">${{order.subtotal.toFixed(2)}}</h3>
                                        </div>
                                    </div>
                                    <div *ngIf="customerServiceFee.value && customerServiceFee.value > 0" fxLayout="row" class="row-margin total">
                                        <div fxLayout="column" fxFlex="50%">
                                            <h3 style="margin-left: 5px; font-size: 600">Checkout Fee</h3>
                                        </div>
                                        <div fxLayout="column" fxFlex="50%" fxLayoutAlign="center end">
                                            <h3 style="margin-right: 5px; font-size: 600">${{order.serviceFee.toFixed(2)}}</h3>
                                        </div>
                                    </div>
                                    <div *ngIf="convenienceFee.value && convenienceFee.value > 0" fxLayout="row" class="row-margin total">
                                        <div fxLayout="column" fxFlex="50%">
                                            <h3 style="margin-left: 5px; font-size: 600">Convenience Fee</h3>
                                        </div>
                                        <div fxLayout="column" fxFlex="50%" fxLayoutAlign="center end">
                                            <h3 style="margin-right: 5px; font-size: 600">${{order.convenienceFee.toFixed(2)}}</h3>
                                        </div>
                                    </div>
                                    <div *ngIf="deliveryFee.value && deliveryFee.value > 0" fxLayout="row" class="row-margin total">
                                        <div fxLayout="column" fxFlex="50%">
                                            <h3 style="margin-left: 5px; font-size: 600">Delivery Fee</h3>
                                        </div>
                                        <div fxLayout="column" fxFlex="50%" fxLayoutAlign="center end">
                                            <h3 style="margin-right: 5px; font-size: 600">${{order.deliveryFee.toFixed(2)}}</h3>
                                        </div>
                                    </div>
                                    <div fxLayout="row" class="row-margin total">
                                        <div fxLayout="column" fxFlex="50%">
                                            <h3 style="margin-left: 5px; font-size: 600">Tax</h3>
                                        </div>
                                        <div fxLayout="column" fxFlex="50%" fxLayoutAlign="center end">
                                            <h3 style="margin-right: 5px; font-size: 600">${{order.tax.toFixed(2)}}</h3>
                                        </div>
                                    </div>

                                    <div fxLayout="row" style="margin-top: 10px" class="total">
                                        <div fxLayout="column" fxFlex="50%">
                                            <h3 style="margin-left: 5px; font-size: 600">Tip</h3>
                                        </div>
                                        <div fxLayout="column" fxFlex="50%" fxLayoutAlign="center end">
                                            <h3 style="margin-right: 5px; font-size: 600">${{tipAmount}}</h3>
                                        </div>
                                    </div>
                                    <div fxLayout="row" style="margin-top: 10px" class="grand-total">
                                        <div fxLayout="column" fxFlex="50%">
                                            <h3 style="margin-left: 5px; font-size: 600">Total</h3>
                                        </div>
                                        <div fxLayout="column" fxFlex="50%" fxLayoutAlign="center end">
                                            <h3 style="margin-right: 5px; font-size: 600">${{totalAmount}}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="order && merchant" fxLayout="row" fxLayoutAlign="center center" style="margin-top: 5px; width: 95%;">
                                    <button mat-stroked-button mat-raised-button matStepperNext class="pay-button" data-test="Scan-To-PayPayButton">Pay</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step *ngIf="false">
                            <div fxLayout="column" fxLayoutAlign="center center">
                                <div fxLayout="row" fxFlex="100%">
                                    <button mat-flat-button="" class="apple-pay-scantopay-checkout apple-pay-button-black-with-text apple-pay-button" (click)="submitPayment('a', stepper)" [disabled]="disableApplePay || processingPayment"></button>
                                </div>
                                <div fxLayout="row" fxFlex="100%">
                                    <button mat-flat-button="" matStepperNext class="payment-button">Credit Card</button>
                                </div>
                                <div class="row" *ngIf="order && order.orderno">
                                    <div class="col-md-12 form-group">
                                        <button mat-stroked-button mat-raised-button matStepperPrevious class="payment-button" [disabled]="processingPayment">Go Back</button>
                                    </div>
                                </div>
                            </div>
                        </mat-step>
                        <mat-step *ngIf="billingFormGroup" [stepControl]="billingFormGroup">
                            <form [formGroup]="billingFormGroup">
                                <div id="divCardForm" class="row" *ngIf="billingFormGroup && order" style="align-items: flex-start;">
                                    <div class="col-12">
                                        <!--                     <div *ngIf="applePayIsSupported" fxLayout="row" fxFlex="100%" fxLayoutAlign="center center">
                      <button mat-flat-button="" class="apple-pay-button-checkout apple-pay-button-black-with-text"
                        (click)="submitPayment('a', stepper)" [disabled]="disableApplePay || processingPayment">Checkout
                        with</button>
                    </div>
                    <div *ngIf="applePayIsSupported" fxLayout="row" fxLayoutAlign="center center">
                      or
                    </div> -->
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <!-- <label>Cardholder Name*</label> -->
                                                <input class="form-control form-control-sm bold-input" placeholder="Cardholder Name*" formControlName="name" (change)="formChange(billingFormGroup)" required />
                                                <mat-error *ngIf="name.invalid && name.errors.invalidName && (name.dirty || name.touched)" class="help-block">First and Last Name Required
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <div style="position:relative;">
                                                    <!-- <input type="tel" class="form-control form-control-sm bold-input" placeholder="Card Number*"
                            name="cardnumber" formControlName="cardnumber" [textMask]="{mask: ccRegex.ccmask}"
                            (input)="ccnumChange()" required /> -->

                                                    <input type="tel" class="form-control form-control-sm bold-input" name="cardnumber" formControlName="cardnumber" [hiddenInput]="true" mask="{{ccMask}}" (input)="ccnumChange()" required />

                                                    <img src="../../../assets/cc_logos/{{cardbrandlogo}}.png" height="32px" alt="" style="position:absolute;right:5px;top:50%;transform:translate(0,-50%);font-size:1em !important;" />
                                                </div>
                                                <mat-error *ngIf="cardnumber.invalid && (cardnumber.dirty || cardnumber.touched)" class="help-block">
                                                    Card Number Required.
                                                </mat-error>
                                                <mat-error *ngIf="cardnumber.errors && cardnumber.errors.invalidCardNumber && (cardnumber.dirty || cardnumber.touched)" class="help-block">
                                                    Card Number Invalid
                                                </mat-error>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col form-group">
                                                <!--  <label>Security Code</label> -->
                                                <input type="tel" class="form-control form-control-sm bold-input" placeholder="Security Code*" [hiddenInput]="true" mask="XXXX" [maxlength]="ccRegex.mincvv" name="cvv" formControlName="cvv" (change)="formChange(billingFormGroup)" required />

                                                <mat-error *ngIf="cvv.invalid && (cvv.dirty || cvv.touched)" class="help-block">
                                                    CVV Required.
                                                </mat-error>
                                                <mat-error *ngIf="cvv.errors && cvv.errors.minlength && (cvv.dirty || cvv.touched)" class="help-block">
                                                    CVV length is {{ccRegex.mincvv}}.
                                                </mat-error>
                                                <mat-error *ngIf="cvv.errors && cvv.errors.maxlength && (cvv.dirty || cvv.touched)" class="help-block">
                                                    CVV length is {{ccRegex.mincvv}}.
                                                </mat-error>
                                                <mat-error *ngIf="cvv.errors && cvv.errors.invalidCVV && (cvv.dirty || cvv.touched)" class="help-block">
                                                    CVV Invalid
                                                </mat-error>
                                            </div>
                                            <div class="col form-group">
                                                <!-- <label>Exp Date</label> -->

                                                <input type="tel" class="form-control form-control-sm bold-input" placeholder="Exp Date*" name="expdate" placeholder="MM/YY" [textMask]="{mask: regexList.ccexpdate, guide: true, keepCharPositions: true}" formControlName="expdate" required (change)="formChange(billingFormGroup)"
                                                />

                                                <mat-error *ngIf="expdate.invalid && (expdate.dirty || expdate.touched)" class="help-block">
                                                    Expiration Date Required.
                                                </mat-error>
                                                <mat-error *ngIf="expdate.invalid && expdate.errors.invalidDate && (expdate.dirty || expdate.touched)" class="help-block">
                                                    Expiration Date Invalid. Please input 2 digit Month and 2 digit Year!
                                                </mat-error>
                                                <mat-error *ngIf="expdate.invalid && expdate.errors.expiredDate && (expdate.dirty || expdate.touched)" class="help-block">
                                                    Credit Card Expired!
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <!--  <label>Billing Address</label> -->
                                                <input class="form-control form-control-sm bold-input" name="street1" placeholder="Billing Address*" formControlName="street1" (change)="formChange(billingFormGroup)" (keyup)="addressLookUp()" [matAutocomplete]="autocomplete" required />
                                                <mat-error *ngIf="street1.invalid && (street1.dirty || street1.touched)" class="help-block">
                                                    Address Required
                                                </mat-error>
                                                <mat-autocomplete #autocomplete="matAutocomplete">
                                                    <mat-option *ngFor="let addr of matches" [value]="addr.formatted_address" (click)="selectedAddress(addr)">
                                                        <small>{{addr.formatted_address}}</small>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <!-- <label>City</label> -->
                                                <input type="text" required="required" name="city" placeholder="City*" formControlName="city" (change)="formChange(billingFormGroup)" class="form-control form-control-sm bold-input" required />
                                                <mat-error *ngIf="city.invalid && (city.dirty || city.touched)" class="help-block">City Required
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <!-- <label>State</label> -->
                                                <mat-form-field appearance="none" class="my-form-field" style="margin-top: -8px;margin-bottom: 18px;max-height: 30px">
                                                    <select matNativeControl id="stp-state" class="form-control form-control-sm bold-input" placeholder="State" formControlName="state" (change)="formChange(billingFormGroup)" required>
                            <option value="" disabled selected>State</option>
                            <option *ngFor="let s of states" [value]="s">
                              {{s}}
                            </option>
                          </select>
                                                    <mat-error *ngIf="state.invalid" class="help-block" style="font-size: 16px">
                                                        State Required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col">
                                                <!-- <label>Zip Code</label> -->
                                                <input type="tel" formControlName="zip" placeholder="Zip Code*" (change)="formChange(billingFormGroup)" class="form-control form-control-sm bold-input" required />
                                                <mat-error *ngIf="zip.invalid && (zip.dirty || zip.touched)" class="help-block">Zipcode Required
                                                </mat-error>
                                                <mat-error *ngIf="zip.hasError('pattern') || zip.hasError('required') && (zip.dirty || zip.touched)">
                                                    Valid Zip Code Required
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <!-- <label>Mobile Number</label> -->
                                                <input type="tel" class="form-control form-control-sm bold-input" placeholder="Mobile Number*" formControlName="phone" maxlength="15" ng-maxlength="15" [textMask]="{mask: regexList.us_phone_masked}" (change)="formChange(billingFormGroup)" />
                                                <mat-error *ngIf="phone.invalid && phone.errors.invalidPhone && (name.dirty || name.touched)">
                                                    Valid Phone Number Required (Tolls Not Allowed)
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 form-group">
                                                <input #paymentEmail type="email" class="form-control form-control-sm bold-input" placeholder="Email Address*" formControlName="email" (change)="formChange(billingFormGroup)" required />
                                                <mat-error *ngIf="email.hasError('pattern') || email.invalid">
                                                    Valid Email Required
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="stepper.selectedIndex === 2 || applePayIsSupported">
                                            <div class="col-md-12 form-group">
                                                <button mat-stroked-button mat-raised-button class="pay-button" [disabled]="billingFormGroup.invalid" [ngClass]="{'disabled': billingFormGroup.invalid || processingPayment }" (click)="processPayment(stepper)" [class.spinner]="processingPayment" [disabled]="processingPayment">Pay ${{totalAmount}}</button>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="order && order.orderno">
                                            <div class="col-md-12 form-group">
                                                <button mat-stroked-button mat-raised-button matStepperPrevious class="pay-button" [disabled]="processingPayment">Go Back</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--                                 <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                </div> -->
                            </form>
                        </mat-step>
                        <mat-step>
                            <div fxLayout="column">
                                <div *ngIf="order" fxLayout="row" fxLayoutAlign="center center">
                                    <h2 *ngIf="!order.orderno" style="text-align: center;color: #000;font-weight: 600;">Unable to retrieve order details or the payment was already processed</h2>
                                    <h2 *ngIf="!orderSubmitError && (paymentResponse && paymentResponse.ResponseCode === 0)" style="text-align: center;color: #000;font-weight: 600;">Successfully paid ${{totalAmount}}
                                    </h2>
                                    <h2 *ngIf="paymentResponse && paymentResponse.ResponseCode === 1" style="text-align: center;color: #000;font-weight: 600;color: red">We are unable to process your payment. Please enter another payment method.</h2>
                                </div>
                                <div fxLayout="row" *ngIf="orderSubmitError && orderSubmitError.message" fxLayoutAlign="center center">
                                    <h2 style="text-align: center;color: #000;font-weight: 600;color: red">{{orderSubmitError.message || paymentResponse.ResponseText}}
                                    </h2>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="center center">
                                    <img *ngIf="paymentResponse && paymentResponse.ResponseCode === 0" mat-card-image src="assets/images/check-box.png" alt="Merchant" class="completed-payment-logo">
                                    <img *ngIf="paymentResponse && paymentResponse.ResponseCode === 1" mat-card-image src="assets/images/declined.png" alt="Merchant" class="completed-payment-logo">
                                </div>
                                <div *ngIf="paymentResponse && paymentResponse.ResponseCode === 0" fxLayout="row" fxLayoutAlign="center center">
                                    <h2 style="text-align: center;color: #000;font-weight: 600;">Thank you for your business!
                                    </h2>
                                </div>
                                <div *ngIf="paymentResponse && paymentResponse.ResponseCode === 0" fxLayout="row" fxLayoutAlign="center center">
                                    <h3>Rate your experience</h3>
                                </div>
                                <div *ngIf="paymentResponse && paymentResponse.ResponseCode === 0" fxLayout="row" aria-label="Rating of this item is 3 out of 5" fxLayoutAlign="center center">
                                    <ng-rating-bar [(value)]="value" [ratingCount]="5" colorActive="orange" [styles]="{margin: '10px', fontSize: '40px'}" (valueChange)="onRatingChange($event)" [disabled]="disableRatings">
                                    </ng-rating-bar>
                                </div>
                                <div *ngIf="paymentResponse && paymentResponse.ResponseCode === 0" fxLayout="row" fxLayoutAlign="center center">
                                    <h3>Tap to Rate</h3>
                                </div>
                                <div *ngIf="paymentResponse && paymentResponse.ResponseCode === 0" fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 5px">
                                    <button mat-stroked-button mat-raised-button (click)="emailReceipt()" class="notification-button" [class.spinner]="processingEmail" [disabled]="processingEmail || emailSent">
                    <mat-icon>mail_outline</mat-icon>
                    Email Receipt
                  </button>
                                    <button mat-stroked-button mat-raised-button (click)="textReceipt()" class="notification-button" [class.spinner]="processingSMS" [disabled]="processingSMS || smsSent" *ngIf="phone && phone.value">
                    <mat-icon>textsms</mat-icon>
                    Text Receipt
                  </button>
                                </div>
                                <div *ngIf="paymentResponse && paymentResponse.ResponseCode === 0" fxLayout="row" fxLayoutAlign="center center" style="margin-top: 5px">
                                    <button mat-stroked-button mat-raised-button (click)="done()" class="notification-button">No
                    Receipt</button>
                                </div>
                                <div *ngIf="paymentResponse && paymentResponse.ResponseCode === 1" fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 5px">
                                    <div class="col-md-12 form-group">
                                        <button mat-stroked-button mat-raised-button matStepperPrevious class="pay-button" [disabled]="processingEmail || processingSMS">Go Back</button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="order && !order.orderno" fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 5px">
                                <button mat-stroked-button mat-raised-button class="pay-button" (click)="done()">Return To Menu</button>
                            </div>
                        </mat-step>
                    </mat-vertical-stepper>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
